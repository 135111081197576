import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// data
import { branding as clientsList } from '@data/clients';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import SectionTitle from '@components/atoms/SectionTitle';
import ContactBox from '@components/molecules/ContactBox';
import FormMessage from '@components/molecules/FormMessage';
import HeroPage from '@components/molecules/heros/HeroPage';
import ItemBullet from '@components/molecules/items/ItemBullet';
import ListCards from '@components/organisms/ListCards';
import ListClients from '@components/organisms/ListClients';
import ListRelatedProjects from '@components/organisms/ListRelatedProjects';

// assets
import imgBranding from '@assets/images/ico-branding.svg';
import iconBullet1 from '@assets/images/bullet_1.svg';
import iconBullet2 from '@assets/images/bullet_2.svg';
import iconBullet3 from '@assets/images/bullet_3.svg';
import imgBrandPositioning from '@assets/images/ico-branding-brand-positioning.svg';
import imgIllustration from '@assets/images/ico-branding-illustration.svg';
import imgLogoCreation from '@assets/images/ico-branding-logo-creation.svg';
import imgMotionDesign from '@assets/images/ico-branding-motion-design.svg';
import imgUserInterface from '@assets/images/ico-branding-user-interface.svg';
import imgVisualIdentity from '@assets/images/ico-branding-visual-identity.svg';
import imgWorkshop from '@assets/images/ico-branding-workshop.svg';
import gifEtjf from '@assets/images/projects/thumbs/PORTRAIT-ETVJ.gif';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const seo = {
	title: 'Swiss Branding Agency - Lausanne, Geneva',
	description:
		'Swiss branding agency in Lausanne and Geneva, specializing in brand positioning and strategy, logo creation and visual identity.',
};

const pageClass = 'page-branding';

const footerRelatedLinks = [
	{ title: 'Strategy', url: '/en/digital-strategy-agency/' },
	{ title: 'Web Solutions', url: '/en/swiss-web-agency/' },
];

const stepsList = [
	{
		image: iconBullet1,
		title: 'Send us your project idea',
		text: [
			'We meet with you to understand your situation and define a suitable design strategy.',
			'Tell us about your needs: logo, brand, identity...',
		],
	},
	{
		image: iconBullet2,
		title: 'Position your brand',
		text: [
			'We use our high-performance collaborative tools to cleverly position your business and create a powerful visual identity:',
			<>
				Strategic workshops
				<br />
				Visual positioning
				<br />
				Brand language and identity
			</>,
		],
	},
	{
		image: iconBullet3,
		title: 'Celebrate your success while continuing to strengthen it',
		text: [
			`It goes without saying that we celebrate your success at the end of the project. But that's just half the story. On top of this, we continue to analyze the opportunities for growth in order to maximize your potential.`,
		],
	},
];

const cardsList = [
	{
		image: imgBrandPositioning,
		title: 'Brand positioning',
		alt: 'Brand positioning',
		text: 'Definition and positioning of brand personality through strategy sessions.',
		// link: { title: 'Read more', url: '/todo' },
	},
	{
		image: imgLogoCreation,
		title: 'Logo creation',
		alt: 'Logo creation',
		text: 'Conception of a distinctive brand acronym that will make you stand out among the competition.',
	},
	{
		image: imgMotionDesign,
		title: 'Motion design',
		alt: 'Motion design',
		text: 'Creation of animations and micro-interactions to accompany your digital media-based brand promotion campaigns.',
		// link: { title: 'Read more', url: '/todo' },
	},
	{
		image: imgVisualIdentity,
		title: 'Visual identity',
		alt: 'Visual identity',
		text: 'Design of graphics, media assets and typography to enhance your brand’s visibility.',
		// link: { title: 'Read more', url: '/todo' },
	},
	{
		image: imgIllustration,
		title: 'Illustration',
		alt: 'Illustration',
		text: 'Creation of an entire visual universe exclusive to your brand: from illustrations to the definition of an iconography that suits your brand personality.',
	},
	{
		image: imgUserInterface,
		title: 'UI – User interface',
		alt: 'UI – User interface',
		text: 'Design of animated digital interfaces that embrace your visual identity and offer coherent, memorable interactions to your digital audience.',
	},
	{
		image: imgWorkshop,
		title: 'Workshops',
		alt: 'Workshops',
		text: (
			<>
				Let’s work together for better results.
				<br />
				What’s your brand DNA about?
				<br />
				Find your tone of voice
				<br />
				No two brands have the same story
			</>
		),
	},
];

// --------------------------------
// #endregion
// --------------------------------

const BrandingPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				projectImages: allFile(
					filter: {
						relativePath: {
							in: ["projects/thumbs/SQUARE-Ackermann.jpg"]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 620) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`
	);

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey].edges.find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	const projectsList = [
		{
			link: '/en/projects/ackermann-branding/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/SQUARE-Ackermann.jpg'
			),
			title: 'Ackermann',
			subtitle: 'Branding – Brand positionning – Packaging',
			linkedProjects: [
				{
					link: '/en/projects/ackermann-typography/',
					title: 'Ackermann handcrafted typography',
				},
			],
		},
		{
			link: '/en/projects/ecole-technique-de-la-vallee-de-joux/',
			image: {
				type: 'gif',
				fluid: [
					{
						src: gifEtjf,
					},
				],
				// the aspectRatio is automatically set for jpg images coming from `useStaticQuery`, but for GIFs we need to specify it to help with size calculation
				aspectRatio: 660 / 976, // width / height
			},
			title: 'Ecole Technique de la Vallée de Joux',
			subtitle: 'Brand Identity',
		},
	];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/fr/agence-branding-suisse/', lang: 'fr' }]}
			title="Branding - Superhuit"
			seo={seo}
		>
			<HeroPage
				image={imgBranding}
				title={'Branding'}
				subtitle={'Swiss branding agency – Lausanne, Geneva'}
				alt="Swiss branding agency – Lausanne, Geneva"
			/>

			<section
				className="grid text-content text-center"
				aria-label="Introduction"
				data-animation-page
			>
				<h2 className="col-big">
					Positioning your brand
					<br /> Increasing its visibility <br /> Growing your
					business
				</h2>
				<p className="p-highlight col-big">
					Brand design and positioning
				</p>
			</section>

			<section className="grid grid-content" aria-label="Description">
				<div className="box text-content" data-animation-page>
					<h2>Grow your business through strategic branding</h2>
					<h3>Do you want to:</h3>
					<ul className="list-two-columns list-default list-brand">
						<li>
							<p>Stand out from the crowd?</p>
						</li>
						<li>
							<p>Be ahead of the curve with trends?</p>
						</li>
						<li>
							<p>Grow your business to its full potential?</p>
						</li>
						<li>
							<p>Create a living brand with essence?</p>
						</li>
					</ul>
					<ButtonPrimary
						className="element-left"
						href="#message"
						text="Then let’s chat"
					/>
				</div>
			</section>

			<section className="grid" aria-label="Clients">
				<div
					className="box text-center text-content"
					data-animation-page
				>
					<p className="uptitle">
						Let our portfolio of clients do the talking
					</p>
					<ListClients clientsList={clientsList} />
				</div>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Projects"
				data-animation-page
			>
				<p className="uptitle">Branding projects</p>
				<h2>Two of our success stories</h2>
				<ListRelatedProjects
					className={['element-spacing', 'col-big']}
					projectsList={projectsList}
				/>
			</section>

			<section aria-label="Process">
				<SectionTitle title="Three steps" />
				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">The next three steps</p>
						<h2>What to do?</h2>
						<div className="list-three-columns">
							{stepsList.map((step, index) => (
								<ItemBullet
									key={`step-${index}`}
									image={step.image}
									title={step.title}
									text={step.text}
								/>
							))}
						</div>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Contact">
				<ContactBox
					title={
						<>
							We want to hear
							<br />
							all about your
							<br />
							challenges
						</>
					}
					link={{ title: 'Email us', url: '#message' }}
					text={
						<>
							Or call:{' '}
							<Link href="tel:+41216521818">
								+41 21 652 18 18
							</Link>
						</>
					}
				/>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Services"
				data-animation-page
			>
				<p className="uptitle">Areas of expertise</p>
				<h2>Branding services</h2>
				<ListCards modifiers="zigzag" cardsList={cardsList} />
			</section>

			<section aria-label="Commitment">
				<SectionTitle title="Our commitment" />

				<div className="grid">
					<div className="text-content box" data-animation-page>
						<p className="uptitle">Our six-point commitment</p>
						<h2>Our commitment is total: in words and in deed</h2>
						<ul className="list-two-columns">
							<li>
								<h4>Size isn’t everything</h4>
								<p>
									Your project matters to us whether you're a
									major player or a fresh-faced start-up. We
									want to help you bring it to life and be
									part of your success. That’s what drives us.
								</p>
							</li>
							<li>
								<h4>Trendy, but also…</h4>
								<p>
									Drawing inspiration from the latest trends
									makes us tick. But you won't find us letting
									these trends cloud our judgement. Instead,
									it's our quest for sustainability,
									performance and originality that guides our
									choices.
								</p>
							</li>
							<li>
								<h4>Our word is our word</h4>
								<p>
									You’ll never catch us making empty promises.
									Are we salespeople? Sure. But we're highly
									specialized in what we sell. We’re frank,
									committed and, most of all, sincere. When we
									give you our word, you can go to the bank
									with it.
								</p>
							</li>
							<li>
								<h4>We're not divas</h4>
								<p>
									We'll bring solid ideas on how to proceed
									efficiently, but “our way or the highway” is
									not in our vocabulary. Chip in with your
									ideas and we won't be offended. Promise.
									Instead, we'll consider your perspective to
									come up with a game plan to move forward
									with your best interests at heart.
								</p>
							</li>
							<li>
								<h4>Digital culture, but also…</h4>
								<p>
									Brands must fit within the interconnected
									ecosystem we know and love, but there's more
									to it than that. There's no denying it -
									we're children of the digital age, who are
									getting our hands dirty playing our part in
									redefining its norms. Yet our favorite tool
									is our brain and we're not scared to go the
									old-fashioned paper route when needed.
								</p>
							</li>
							<li>
								<h4>Better together</h4>
								<p>
									Communication is the beating heart of
									successful relationships. That’s why
									face-to-face meetings are our jam. Getting
									together for strategy sessions helps us
									build a common understanding of your
									challenges, which results in better
									projects.
								</p>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Send Message" id="message">
				<FormMessage
					title="Need a hand?"
					text="Email us at"
					contact="hello@superhuit.ch"
				/>
			</section>
		</Layout>
	);
};

export default BrandingPage;
